import React from 'react';
import { useTranslation } from 'react-i18next';

// IMGS
import msaWhite from "../assets/img/grupo-msa-white.svg"
import mail from "../assets/img/mail-icon.svg"
import marker from "../assets/img/marker-pin-icon.svg"
import phone from "../assets/img/phone-icon.svg"

function Footer() {
    const { t, i18n } = useTranslation();
    return (
        <>
            <footer className={"siteFooter"}>
                <div><img src={msaWhite} width="132" alt="Grupo MSA Logo"/></div>
                <div><img src={mail} alt=""/><a href="mailto:info@msa.com.ar">info@msa.com.ar</a></div>
                <div><img src={marker} alt=""/><p>Atuel 362, CABA, Argentina</p></div>
                <div><img src={phone} alt=""/><p>+54 11 6091-1540</p></div>
                <div><a href={t('policy')} target="_blank">Política SGI</a></div>
            </footer>
        </>
    );
}

export default Footer;