import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainContent from './components/MainContent';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
//import Noticia from './pages/Noticia';


function RoutesConfig() {
  return (
    <Routes>
      <Route path="/" element={<MainContent />} />
      <Route path="/somos" element={<Page1 />} />
      <Route path="/noticias" element={<Page1 />} />
      <Route path="/noticias/:id" element={<Page2 />} />
     
    </Routes>
  );
}

export default RoutesConfig;