import React from 'react';

function Page2() {
  return (
    <div>
      <h2>Página 2</h2>
      <p>Contenido de la Página 2.</p>
    </div>
  );
}

export default Page2;