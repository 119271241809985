import React from 'react';

function Page1() {
  return (
    <div>
      <h2>Página 1</h2>
      <p>Contenido de la Página 1.</p>
    </div>
  );
}

export default Page1;