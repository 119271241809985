import React, {useRef, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';

//IMGS
import linkedinLogo from '../assets/img/linkedin-icon.svg';
import logoMSA from '../assets/img/grupo-msa-logo.svg';
import xcruza from '../assets/img/xcruza.svg'
import comitia from '../assets/img/comitia.svg'
import singular from '../assets/img/singular-stories.svg'
import fundacion from '../assets/img/fun-demos.svg'
import singulraIT from '../assets/img/singular-it.svg'
import economatica from '../assets/img/economatica.svg'
import closeMenu from '../assets/img/chevron-right.svg'

function Header() {

    const menuRef = useRef(null);
    const toggleButtonRef = useRef(null);
    const overlayRef = useRef(null);
    const closeButtonRef = useRef(null);

    useEffect(() => {
        const menu = menuRef.current;
        const toggleButton = toggleButtonRef.current;
        const closeButton = closeButtonRef.current;
        const overlay = overlayRef.current;

        const toggleMenu = () => {
            menu.classList.toggle('open');
            //overlay.classList.toggle('show');
        };

        toggleButton.addEventListener('click', toggleMenu);
        closeButton.addEventListener('click', toggleMenu);

        // Event listeners para los enlaces internos
        const internalLinks = document.querySelectorAll('.internal-link');
        internalLinks.forEach((link) => {
            link.addEventListener('click', () => {
                toggleMenu()
            });
        });

        return () => {
            toggleButton.removeEventListener('click', toggleMenu);
        };
    }, []);
    

    const { t, i18n } = useTranslation();
    const location = useLocation();
    const isHomePage = location.pathname === '/';
  
    const changeLanguage = (event, lng) => {
        const botones = document.querySelectorAll('.language-btn');
        botones.forEach((boton) => {
            boton.classList.remove('active');
        });
        event.target.classList.add('active')
        i18n.changeLanguage(lng);
    };  

    return (
        <>
        <div class="preHeader">
            <div class="preHeader-contact-info">
                <a href="mailto:Info@msa.com.ar">Info@msa.com.ar</a>
                <p>+54 11 6091-1540</p>
                <p>Atuel 362, CABA, Argentina</p>
            </div>
            <a class="preHeader-linkedin-link" href="https://www.linkedin.com/company/grupo-msa/">
                <img src={linkedinLogo} alt="Linkedin Icon"/>
            </a>
        </div>
        <header class="siteHeader">
            <div class="mobileMenu">
                <a href="/">
                    <img src={logoMSA}  alt="Grupo MSA - Logo"/>
                </a>
                <div class="menuToggler" id="menuToggler" ref={toggleButtonRef}>
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </div>
            </div>
            <nav class="mainNav" id="mainNav" ref={menuRef}>
                <div class="brand">
                    <a href="/">
                        <img src={logoMSA} alt="Grupo MSA - Logo"/>
                    </a>
                    <button ref={closeButtonRef} id="closeMenu"><img src={closeMenu} alt=""/></button>
                </div>
                <ul class="projectsNav">
                    <li><a href="https://comitiacorp.com/" target='_blank'><img src={comitia} alt=""/></a></li>
                    <li><a href="https://xcruza.com/" target='_blank'><img src={xcruza} alt=""/></a></li>
                    <li><a href="https://singularstories.lat/" target='_blank'><img src={singular} alt=""/></a></li>
                    <li><a href="https://www.fundacion.com.ar" target='_blank'><img src={fundacion} alt=""/></a></li>
                    <li><a href="https://singularit.com.ar/" target='_blank'><img src={singulraIT} alt=""/></a></li>
                    <li><a href="https://economatica.com/" target='_blank'><img src={economatica} alt=""/></a></li>
                </ul>
                <div class="moreLinks">
                    {
                        //<Link className={"text-btn"} to="/somos">{t('Somos ')}<strong>MSA</strong></Link> 
                    }    
                    <ScrollLink className={"text-btn internal-link"} to="somos" smooth={true} duration={500}>{t('somos')} <strong>MSA</strong></ScrollLink>
                    <ScrollLink className={"btn btn-primary internal-link"} to="contacto" smooth={true} duration={500}>{t('contact-title')}</ScrollLink>
                    <ul class="language-selector">
                        <li><button onClick={(e) => changeLanguage(e,'es')} class="language-btn active internal-link">ES</button></li>
                        <li><button onClick={(e) => changeLanguage(e,'en')} class="language-btn internal-link">EN</button></li>
                    </ul>
                    <a class="preHeader-linkedin-link" href="https://www.linkedin.com/company/grupo-msa/">
                        <img src={linkedinLogo} alt="Linkedin Icon"/>
                    </a>
                </div>
            </nav>
        </header>
        <div ref={overlayRef} id="mobileNavOverlay" className={"mobile-nav-overlay"}></div>
        </>
    );
}

export default Header;