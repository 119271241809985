import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

//IMGS
import dummievideo from '../assets/img/dummie-video.jpg'
import comitia from "../assets/img/comitia-img.jpg"
import comitialigth from "../assets/img/comitia-light.svg"
import projectDeco2 from "../assets/img/project-deco-2.svg"
import projectDeco1 from "../assets/img/project-deco-1.svg"
import singularS from "../assets/img/singular-stories-img.jpg"
import singularSlight from "../assets/img/singular-stories-light.svg"
import xcruza from "../assets/img/xcruza-img.jpg"
import xcruzalight from "../assets/img/xcruza-light.svg"
import economatica from "../assets/img/economatica-img.jpg"
import economaticalight from "../assets/img/economatica-light.svg"
import fundacion from "../assets/img/fundemos-img.jpg"
import fundacionlight from "../assets/img/fun-demos-light.svg"
import singularIT from "../assets/img/singular-it-img.jpg"
import singularITlight from "../assets/img/singular-it-light.svg"
import singularITblue from "../assets/img/singular-it-blue.svg"
import comitia600 from '../assets/img/comitia-grey-600.svg'
import xcruza600 from "../assets/img/xcruza-grey-600.svg"
import singularS600 from "../assets/img/singular-stories-grey-600.svg"
import singularIT600 from "../assets/img/singular-it-grey-600.svg"
import economatica600 from "../assets/img/economatica-grey-600.svg"
import fundemos600 from "../assets/img/fundemos-grey-600.svg"
import diagram from "../assets/img/grupo-msa-diagram.png"
import diagramMobile from "../assets/img/grupo-msa-diagram-mobile.png"
import msaLogoWhite from "../assets/img/grupo-msa-white.svg"
import andreani from "../assets/img/andreani-logo.svg"
import aws from "../assets/img/aws-logo.svg"
import intel from "../assets/img/intel-logo.svg"
import stLogo from "../assets/img/st-logo.svg" 
import iram from "../assets/img/iram.jpg"
import iso from "../assets/img/iso.jpg"
import msaImage from "../assets/img/msa-image-1.jpg"
import msaImageMob from "../assets/img/msa-image-1-mobile.jpg"
import mapa from "../assets/img/map.svg"
import mapaMobile from "../assets/img/map-mobile.png"
import mail from "../assets/img/mail-primary-blue.svg"
import phone from "../assets/img/phone-primary-blue.svg"
import videowebm from "../assets/video/MSA-webm-no-audio.webm"
import videomp4 from "../assets/video/MSA-mp4-no-audio.mp4"

function MainContent() {

  const { t } = useTranslation();

  const contactSchema = Yup.object().shape({
    region: Yup.string(),
    organism: Yup.string(),
    name: Yup.string().required('El nombre es obligatorio'),
    phone: Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null; // O undefined, dependiendo de tus necesidades
      }
      return value;
    })
    .nullable(),
    email: Yup.string().email('Email inválido').required('El email es obligatorio'),
    message: Yup.string().required('El mensaje es obligatorio')
  });

  const [data, setData] = useState([]);
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactSchema),
  });

  const onSubmit = async (data) => {
    
    var payload = {
      email: data.email,
      message: `Region: ${data.region} - Organismo: ${data.organism} - Telefono: ${data.phone} - Mensaje: ${data.message}`,
      name: data.name,
      sendTo :  "contactus@comitiacorp.com"
    }
  
    try {
      const response = await axios.post('https://msa-emails.vercel.app/api/send-email', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // O el origen específico
        },
      });
      console.log('Respuesta del servidor:', response.data);
      reset()
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
      // Manejar el error
    }
  };

  useEffect(() => {
    //fetch('http://localhost:3000/news.json')
    fetch('/noticias/news.json') 
      .then(response => response.json())
      .then(data => setData(data.slice(0, 3))) // Tomamos solo las primeras 3 
      .catch(error => console.error('Error al cargar los datos:', error));
  }, []);

  return (
    <main className={"App-main"}>
      <section className={"home-hero"}>
            <div className={"home-hero-text"}>
                <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.5 34.8451C26.5475 34.8451 24.1549 32.4525 24.1549 29.5C24.1549 26.5474 26.5475 24.1548 29.5 24.1548C32.4525 24.1548 34.8451 26.5474 34.8451 29.5C34.8451 32.4525 32.4525 34.8451 29.5 34.8451ZM29.5 25.3257C27.2092 25.3257 25.3257 27.2092 25.3257 29.5C25.3257 31.7907 27.2092 33.6743 29.5 33.6743C31.7908 33.6743 33.6743 31.7907 33.6743 29.5C33.6743 27.2092 31.7908 25.3257 29.5 25.3257Z" fill="white"/>
                    <path d="M30.0857 0H28.9148V15.1191H30.0857V0Z" fill="white"/>
                    <path d="M30.0857 43.8809H28.9148V59H30.0857V43.8809Z" fill="white"/>
                    <path d="M59.0254 28.9146H43.9063V30.0854H59.0254V28.9146Z" fill="white"/>
                    <path d="M15.1191 28.9146H0V30.0854H15.1191V28.9146Z" fill="white"/>
                    <path d="M49.7308 8.0122L36.5383 21.2047L37.7802 22.4466L50.9727 9.25406L49.7308 8.0122Z" fill="white"/>
                    <path d="M20.5435 37.1926L8.01689 49.7192L9.25875 50.9611L21.7853 38.4345L20.5435 37.1926Z" fill="white"/>
                    <path d="M9.28283 8.01476L8.04097 9.25662L20.5676 21.7832L21.8094 20.5414L9.28283 8.01476Z" fill="white"/>
                    <path d="M37.1619 37.2098L35.9815 38.5102L49.7773 51.0327L50.9577 49.7322L37.1619 37.2098Z" fill="white"/>
                </svg>
                <h1 className={"fs-66 fw-300"}>{t('hero-title-mob')}</h1>
                <p className={"lh-130"}>{t('hero-text-mob')}</p>
                <ScrollLink className={"btn btn-primary btn-large"} to="contacto" smooth={true} duration={500}>{t('hero-btn')}</ScrollLink>
            </div>
            <div className={"home-hero-video"}>
                <img src={dummievideo} alt=""/>
            </div>
            <div className={"home-hero-logos"}>
                <a href="https://comitiacorp.com" target="_blank"><img src={comitia600} alt="Comitia Logo"/></a>
                <a href="https://xcruza.com" target="_blank"><img src={xcruza600} alt="xCruza Logo"/></a>
                <a href="https://singularstories.lat" target="_blank"><img src={singularS600} alt="Singular Stories Logo"/></a>
                <a href="https://singularit.com.ar" target="_blank"><img src={singularIT600} alt="Singular IT Logo"/></a>
                <a href="https://economatica.com" target="_blank"><img src={economatica600} alt="Economatica Logo"/></a>
                <a href="https://www.fundemos.com.ar" target="_blank"><img src={fundemos600} alt="Fundemos Logo"/></a>
            </div>
      </section>

      <section className={"home-hero-desktop"}>
            <h1 className={"fs-66 fw-300"}>{t('hero-title-mob')}</h1>
            <video autoPlay muted loop>
                <source src={videowebm} type="video/webm" autoplay/>
                <source src={videomp4} type="video/mp4" autoplay/>
            </video>
      </section>
      
      <section className={"who"} id="somos">
            <div className={"container grid-3-9"}>
                <div>
                    <h2 className={"fw-300 fs-22 lh-130"}>{t('who-title')}</h2>
                </div>
                <div>
                    <p className={"fw-300 fs-40 m-0 lh-130"}>{
                    
                    t('who-text', { 'ecosistema' : 'ecosistema', 0: '<strong></strong>' })
                    }</p>
                </div>
            </div>
      </section>

      <section className={"projects"}>
            <a className={"project"} href="https://comitiacorp.com" target="_blank">
                <div className="project-col">
                    <img className="project-logo" src={comitia} alt=""/>
                    <div className="project-logo-overlay">
                        <img src={comitialigth} alt=""/>
                    </div>
                </div>
                <div className="project-col bg-blue-600">
                    <div className="project-desc">
                        <h2>{t('project-comitia-tit')}</h2>
                        <p>{t('project-comitia-text')}</p>
                    </div>
                    <div className="project-deco">
                        <img src={projectDeco2} alt=""/>
                    </div>
                </div>
            </a>
            <a className="project" href="https://singularstories.lat" target="_blank">
                <div className="project-col bg-light-blue">
                    <div className="project-desc">
                        <h2>{t('project-singularS-tit')}</h2>
                        <p>{t('project-singularS-text')}</p>
                    </div>
                </div>
                <div className="project-col">
                    <img className="project-logo" src={singularS} alt=""/>
                    <div className="project-logo-overlay">
                        <img src={singularSlight} alt=""/>
                    </div>
                </div>
            </a>
            <a className="project" href="https://xcruza.com" target="_blank">
                <div className="project-col bg-blue-600">
                    <div className="project-desc">
                        <h2>{t('project-xcruza-tit')}</h2>
                        <p>{t('project-xcruza-text')}</p>
                    </div>
                </div>
                <div className="project-col">
                    <img className="project-logo" src={xcruza} alt=""/>
                    <div className="project-logo-overlay">
                        <img src={xcruzalight} alt=""/>
                    </div>
                </div>
            </a>
            <a className="project" href="https://economatica.com" target="_blank">
                <div className="project-col">
                    <img className="project-logo" src={economatica} alt=""/>
                    <div className="project-logo-overlay">
                        <img src={economaticalight} alt=""/>
                    </div>
                </div>
                <div className="project-col bg-rich-black">
                    <div className="project-desc">
                        <h2>{t('project-economatica-tit')}</h2>
                        <p>{t('project-economatica-text')}</p>
                    </div>
                </div>
            </a>
            <a className="project" href="https://www.fundemos.com.ar" target="_blank">
                <div className="project-col">
                    <img className="project-logo" src={fundacion} alt=""/>
                    <div className="project-logo-overlay">
                        <img src={fundacionlight} alt=""/>
                    </div>
                </div>
                <div className="project-col bg-deep-blue">
                    <div className="project-desc">
                        <h2>{t('project-fundemos-tit')}</h2>
                        <p>{t('project-fundemos-text')}</p>
                    </div>
                    <div className="project-deco">
                        <img src={projectDeco1} alt=""/>
                    </div>
                </div>
            </a>
            <a className="project" href="https://singularit.com.ar" target="_blank">
                <div className="project-col">
                    <img className="project-logo" src={singularIT} alt=""/>
                    <div className="project-logo-overlay">
                        <img src={singularITlight} alt=""/>
                    </div>
                </div>
                <div className="project-col bg-rich-black">
                    <div className="project-desc">
                        <h2>{t('project-singularit-tit')}</h2>
                        <p>{t('project-singularit-text')}</p>
                    </div>
                </div>
            </a>
        </section>

        <section className="projects-mobile">
            <a href="https://comitiacorp.com" target="_blank">
                <div className="project-col">
                    <img className="project-logo" src={comitia} alt=""/>
                    <div className="project-logo-overlay">
                        <img src={comitialigth} alt=""/>
                    </div>
                </div>
            </a>
            <a href="https://singularstories.lat" target="_blank">
                <div className="project-col bg-blue-600">
                    <img src={singularSlight} alt=""/>
                </div>
            </a>
            <a href="https://xcruza.com" target="_blank">
                <div className="project-col bg-rich-black">
                    <img src={xcruzalight} alt=""/>
                </div>
            </a>
            <a href="https://economatica.com" target="_blank">
                <div className="project-col">
                    <img className="project-logo" src={economatica} alt=""/>
                    <div className="project-logo-overlay">
                        <img src={economaticalight} alt=""/>
                    </div>
                </div>
            </a>
            <a href="https://www.fundemos.com.ar" target="_blank">
                <div className="project-col">
                    <img className="project-logo" src={fundacion} alt=""/>
                    <div className="project-logo-overlay">
                        <img src={fundacionlight} alt=""/>
                    </div>
                </div>
            </a>
            <a href="https://singularit.com.ar" target="_blank">
                <div className="project-col bg-light-blue">
                    <img src={singularITblue} alt=""/>
                </div>
            </a>
      </section>

      
      <section className={"diagram"}>
          <img className={"diagram-desk"} src={diagram} alt=""/>
          <img className={"diagram-mob"} src={diagramMobile} alt=""/>
      </section>

      <section className={"bg-blue-600 dream"}>
          <div className={"container grid-4-7"}>
              <div className={"dream-title"}>
                  <h2 className={"fs-60 fw-300 lh-120"}>{t('dream-title')}</h2>
                  <p className={"fs-18 fw-300 lh-120"}>{t('dream-lead')}</p>
              </div>
              <div className={"dream-copy"}>
                  <p className={"fs-40 fw-300 lh-130"}>{t('dream-text')}</p>
              </div>
          </div>
      </section>

      <section className={"news"} id='news'>
          <div className={"container news-feed-header"}>
              <h3 className={"fw-600 fs-16 lh-130"}>{t('news-pre')}</h3>
              <h2 className={"fw-600 fs-36 lh-120"}>{t('news-title')}</h2>
              <p>{t('news-lead')}</p>
              <Link to={`https://www.msa.com.ar/noticias/index.html`} target="_blank" className={"btn btn-primary"} >{t('news-btn')}</Link>
          </div>
          <div className={"container news-feed"}>
          {data.map((noticia, index) => (
            <article key={index}>
                <Link to={`https://www.msa.com.ar/noticias/articulo.html?id=${noticia.id}`} target="_blank">
                    <div className={"news-img"}>
                        <img src={`/noticias${noticia.image_thumb}`} alt={noticia.title}/>
                    </div>
                    <div className={"news-date"}>{noticia.category}</div>
                    <h2 className={"news-title"}>{noticia.title}</h2>
                    <p className={"news-lead"}>{noticia.description_short}</p>
                    <span className={"read-more"}>Leer más...</span>
                </Link>
            </article>
          ))}
          </div>
      </section>

      <section className={"what bg-deep-blue"}>
          <div className={"container"}>
              <img src={msaLogoWhite} alt="Grupo MSA - Logo"/>
              <p className={"fs-60 fw-300 lh-120 t-align-center m-0"}>{t('what-text')}</p>
          </div>
      </section>

      <section className={"quality"}>
          <div className={"quality-text"}>
              <h2 className={"fs-60 fw-300 lh-120"}>{t('quali-title')}</h2>  
              <div>
                  <p className={"tt-u fs-18 fw-500"}>{t('partners')}</p>
                  <ul className={"partners"}>
                      <li><img src={andreani} alt="Andreani Logo"/></li>
                      <li><img src={aws} alt="Amazon Web Services Logo"/></li>
                      <li><img src={intel} alt="Intel Logo"/></li>
                      <li><img src={stLogo} alt="ST Logo"/></li>
                  </ul>
              </div>
              <p className={"fs-22 fw-300 lh-130"}>{t('quali-text')}</p>
          </div>
          <div className={"square-grid"}>
              <div className={"bg-light-blue-200"}>
                  <h3 className={"fs-24 lh-130 fw-500"}>{t('quali-sgi')}</h3>
                  <p className={"fs-16 lh-130 fw-300"}>{t('quali-sgi-text')}</p>
              </div>
              <div className={"quality-logo"}>
                  <img src={iram} alt="" width="106"/>
              </div>
              <div className={"quality-logo"}>
                  <img src={iso} alt="" width="106"/>
              </div>
              <div className="bg-blue-600">
                  <h3 className={"fs-24 lh-130 fw-500"}>{t('quali-ec')}</h3>
                  <p className={"fs-16 lh-130 fw-300"}>{t('quali-ec-text')}</p>
              </div>
          </div>
      </section>

      <section className={"bg-light-blue-200"}>
          <div className={"welcome"}>
              <div className={"welcome-col"}>
                  <div className={"welcome-text"}>
                      <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M25.02 0.0634766V12.8835" stroke="#1D315D" stroke-miterlimit="10"/>
                          <path d="M25.02 37.3037V50.1237" stroke="#1D315D" stroke-miterlimit="10"/>
                          <path d="M50.05 25.0933H37.23" stroke="#1D315D" stroke-miterlimit="10"/>
                          <path d="M12.81 25.0933H0" stroke="#1D315D" stroke-miterlimit="10"/>
                          <path d="M42.72 7.39355L31.52 18.5936" stroke="#1D315D" stroke-width="1.5" stroke-miterlimit="10"/>
                          <path d="M17.95 32.1733L7.33 42.7933" stroke="#1D315D" stroke-width="1.5" stroke-miterlimit="10"/>
                          <path d="M7.33 7.39355L17.95 18.0136" stroke="#1D315D" stroke-width="1.5" stroke-miterlimit="10"/>
                          <path d="M31.02 32.1733L42.72 42.7933" stroke="#1D315D" stroke-width="1.5" stroke-miterlimit="10"/>
                      </svg>                            
                      <h2 className={"fs-60 fw-300 lh-120"}>{t('welcome-title')}</h2>
                      <p className={"fs-32 fw-300 lh-120"}>{t('welcome-text')}</p>
                      <ScrollLink className={"btn btn-primary btn-large"} to="somos" smooth={true} duration={500}>{t('welcome-btn')}</ScrollLink>
                  </div>
              </div>
              <div>
                  <img className={"welcome-img-desk"} src={msaImage} alt=""/>
                  <img className={"welcome-img-mob"} src={msaImageMob} alt=""/>
              </div>
          </div>
      </section>

      <section className={"map"}>
          <div className={"map-container"}>
              <div className={"locations"}>
                  <p>{t('map-tampa')}</p>
                  <p>{t('map-miami')}</p>
                  <p>{t('map-colonia')}</p>
                  <p>{t('map-dubai')}</p>
                  <p>{t('map-barcelona')}</p>
                  <p>{t('map-bsas')}</p>
                  <p>{t('map-asuncion')}</p>
              </div>
              <img src={mapa}/>
          </div>
          <div className={"map-mobile"}>
            <img src={mapaMobile} alt=''/>
          </div>
      </section>

      <section className={"contact"} id="contacto">
          <div className={"container grid-4-8"}>
                <div>
                    <h2 className={"fs-60 lh-120 fw-300 m-0"}>{t('contact-title')}</h2>
                    <div className={"prensa-contact"}>
                        <p className={"fs-18 lh-120"}>{t('contact-press-title')}</p>
                        <p className={"fs-18 lh-120 fw-300"}>{t('contact-press-name')}</p>
                        <a className={"fs-16 lh-130 fw-300"} href='mailto:pcuomo@mapa-comunicacion.com'><img src={mail} alt="" /> pcuomo@mapa-comunicacion.com</a>
                        <p className={"fs-16 lh-130 fw-300"}><img src={phone} alt="" /> +54 9 11 4089-6667</p>
                    </div>
                </div>
              <div>
                <form onSubmit={handleSubmit(onSubmit)}>
                      <div className={"grid-1-1 gap-16 mb-24"}>
                          <div className={`${(errors.region ? 'has-error' : '')}`}>
                              <label htmlFor="region" className={"display-block mb-6"}>{t('label-region')}</label>
                              <input {...register('region')} className={"w-100 mb-6 fs-16 lh-130"} type="text" name="region" placeholder={t('ph-region')}/>
                              <small className={"display-block m-0"}>{t('help-region')}</small>
                              {errors.region && <span>{errors.region.message}</span>}
                          </div>
                          <div className={`${(errors.organism ? 'has-error' : '')}`}>
                              <label htmlFor="organism" className={"display-block mb-6"}>{t('label-org')}</label>
                              <input {...register('organism')} className={"w-100 mb-6 fs-16 lh-130"} type="text" name="organism" placeholder={t('ph-org')}/>
                              <small className={"display-block m-0"}>{t('help-org')}</small>
                              {errors.organism && <span>{errors.organism.message}</span>}
                          </div>
                      </div>
                      <div className={`mb-24 ${(errors.name ? 'has-error' : '')}`}>
                          <label htmlFor="name" className={"display-block mb-6"}>{t('label-name')} <span className={"color-blue-600"}>*</span></label>
                          <input {...register('name')} className={"w-100 fs-16 lh-130"} type="text" name="name" placeholder={t('ph-name')} />
                          {errors.name && <span>{errors.name.message}</span>}
                      </div>
                      <div className={"grid-1-1 gap-16 mb-24"}>
                          <div className={`${(errors.email ? 'has-error' : '')}`}>
                              <label htmlFor="email" className={"display-block mb-6"}>{t('label-email')} <span className={"color-blue-600"}>*</span></label>
                              <input {...register('email')} className={"w-100 fs-16 lh-130"} type="email" name="email" placeholder={t('ph-email')}/>
                              {errors.email && <span>{errors.email.message}</span>}
                          </div>
                          <div className={`${(errors.phone ? 'has-error' : '')}`}>
                              <label htmlFor="phone" className={"display-block mb-6"}>{t('label-tel')}</label>
                              <input {...register('phone')} className={"w-100 fs-16 lh-130"} type="tel" name="phone" placeholder={t('ph-tel')}/>
                              {errors.phone && <span>{errors.phone.message}</span>}
                          </div>
                      </div>
                      <div className={`mb-24 ${(errors.message ? 'has-error' : '')}`}>
                          <label htmlFor="message" className={"display-block mb-6"}>{t('label-mes')} <span className={"color-blue-600"}>*</span></label>
                          <textarea {...register('message')} className={"w-100 fs-16 lh-130"} name="message" placeholder={t('ph-mes')}></textarea>
                          {errors.message && <span>{errors.message.message}</span>}
                      </div>
                      <div><button type="submit" className={"btn btn-primary"}>{t('form-btn')}</button></div>
                  </form>
              </div>
          </div>
      </section>
    </main>
  );
}

export default MainContent;